<template>
  <div class="form-body">
    <div class="website-logo">
      <a href="/">
        <div class="logo">
          <img
            class="logo-size"
            src="@/assets/images/logo-aplikasi.png"
            alt="logo-aplikasi"
          />
        </div>
      </a>
    </div>
    <div class="row">
      <div class="img-holder">
        <div class="bg"></div>
        <div class="info-holder">
          <h3>Kelola Perekrutan Pegawai Dengan Mudah.</h3>
          <p>Sistem Pendukung Keputusan Perekrutan Pegawai Berbasis Website</p>
          <img src="@/assets/images/ui-login.jpg" alt="ui-login" />
        </div>
      </div>
      <div class="form-holder">
        <div class="form-content">
          <div class="form-items">
            <div class="website-logo-inside">
              <a href="/">
                <div class="logo">
                  <img
                    class="logo-size"
                    src="@/assets/images/logo-aplikasi.png"
                    alt="logo-aplikasi"
                  />
                </div>
              </a>
            </div>
            <div class="page-links">
              <a href="#" class="active">Login</a>
            </div>
            <form @submit.prevent="login">
              <div v-if="loginFailed" class="alert alert-danger">
                Email atau Password Anda salah.
              </div>

              <div class="form-group">
                <input
                  class="form-control form-control-user"
                  type="email"
                  name="email"
                  placeholder="Input Email"
                  v-model="user.email"
                />
              </div>
              <div class="form-group" id="show_hide_password">
                <div class="input-group">
                  <input
                    id="password"
                    :type="password_type"
                    name="pass"
                    class="form-control form-control-user"
                    required=""
                    placeholder="Input Password"
                    v-model="user.password"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" @click="togglePassword()"
                      ><i class="fa fa-eye"></i
                    ></span>
                  </div>
                </div>
              </div>
              <div class="form-button">
                <button id="submit" type="submit" class="ibtn">Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import * as authService from "@/services/auth/auth-services"

export default {
  name: "Login",

  data() {
    return {
      password: "",
      password_type: "password",
      password_toggle_element: "show",
      // image: require('@/assets/images/bg-login.jpg'),
      //state loggedIn with localStorage
      loggedIn: localStorage.getItem("loggedIn"),
      //state token
      token: localStorage.getItem("token"),
      //state user
      user: [],
      //state validation
      validation: [],
      //state login failed
      loginFailed: null,
    }
  },

  methods: {
    togglePassword() {
      this.password_type =
        this.password_type === "password" ? "text" : "password"
    },
    loginProcess(user) {
      if (this.user.email && this.user.password) {
        authService
          .loginCookie()
          .then((response) => {
            //debug cookie
            console.log(response)
            authService
              .login(user)
              .then((res) => {
                //debug user login
                console.log(res)
                if (res.data.success) {
                  //set localStorage
                  localStorage.setItem("loggedIn", "true")
                  //set localStorage Token
                  localStorage.setItem("token", res.data.token)
                  //change state
                  this.loggedIn = true
                  //redirect dashboard
                  return this.$router.push({ name: "dashboard" })
                } else {
                  //set state login failed
                  this.loginFailed = true
                }
              })
              .catch((error) => {
                this.loginFailed = true
                console.log(error)
              })
          })
      }

      this.validation = []

      if (!this.user.email) {
        this.validation.email = true
      }

      if (!this.user.password) {
        this.validation.password = true
      }
    },

    login() {
      this.loginProcess({
        email: this.user.email,
        password: this.user.password,
      })
    },

  },

  //check user already logged in
  mounted() {
    if (this.loggedIn) {
      return this.$router.push({ name: "dashboard" })
    }
  },
}
</script>
