import {http} from '@/network/base-url'

export function showData(){
    return http().get('/applicant_data_views')
}

export function showById(id){
    return http().get('/applicant_data_views' + '/' + id)
}

export function deleteById(id){
    return http().delete('/applicant_data' + '/' + id)
}