<template>
    <div class="questions">
        <div class="container mt-5">
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            UPDATE DATA
                            <button onClick="window.history.back()" class="btn btn-sm btn-warning" style="float: right;">BACK</button>
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="questionUpdate">

                                <b-form-group id="input-group-3" label="Question Category" label-for="input-3">
                                    <b-form-select
                                        id="input-3"
                                        v-model="question.category_id"
                                        :options="items"
                                        value-field="id"
                                        text-field="name" 
                                        required
                                    >
                                        <template #first>
                                            <b-form-select-option :value="null" disabled>-- Pilih Question Category --</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div v-if="validation.category_id">
                                        <div class="alert alert-danger mt-1" role="alert">
                                            {{ validation.category_id[0] }}
                                        </div>
                                    </div>
                                </b-form-group>

                                <div class="form-group">
                                    <label>Question</label>
                                    <input type="text" class="form-control" v-model="question.question"
                                           placeholder="Input Question">
                                    <div v-if="validation.question">
                                        <div class="alert alert-danger mt-1" role="alert">
                                            {{ validation.question[0] }}
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Key M</label>
                                    <input type="text" class="form-control" v-model="question.keym"
                                           placeholder="Input Key M">
                                    <div v-if="validation.keym">
                                        <div class="alert alert-danger mt-1" role="alert">
                                            {{ validation.keym[0] }}
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Key L</label>
                                    <input type="text" class="form-control" v-model="question.keyl"
                                           placeholder="Input Key L">
                                    <div v-if="validation.keyl">
                                        <div class="alert alert-danger mt-1" role="alert">
                                            {{ validation.keyl[0] }}
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <button type="submit" class="btn btn-md btn-success" style="float: right;">UPDATE</button>
                                    <button type="reset" class="btn btn-md btn-danger mr-3" style="float: right;">RESET</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import * as questionCategoryService from "@/services/questionCategory/questionCategory-services"

export default {
    data() {
        return {
            question: {},
            validation: [],
            items: [],
        }
    },
    watch: {
        items(val) {
            console.log('Total Data Category: '+val.length)   
        },
    },
    mounted() {
        // ShowData
        this.showData()
    },
    created() {
        axios.get(`http://localhost:8081/api/question/${this.$route.params.id}`)
            .then((response) => {
            this.question = response.data.data
        })
    },
    methods: {
        showData: async function() {
            try {
                const response = await questionCategoryService.showData()
                console.log(response)
                this.items.unshift(response.data) 
                this.items = response.data.data
                // console.log(this.items)
            } catch (error) {
                this.flashMessage.error({
                title: 'Error',
                message: "Something went wrong, please refresh or call IT Team",
                time: 2000,
                })
            }
        },
        questionUpdate() {
            axios.post(`http://localhost:8081/api/question/${this.$route.params.id}`, this.question)
                .then((response) => {
                    this.alertSuccess(response.data.message)
                    this.$router.push({
                        name: 'question'
                    })
                    console.log(response.data)
                }).catch(error => {
                    this.alert(error)
                    this.validation = error.response.data.data
            })
        },
        alertSuccess(message){
            this.$swal('Success!',
                message,
                'success'
            )
        },
        alert(message){
            this.$swal({
                title: "",
                text: message,
                icon: "error",
                showConfirmButton: true
            })
        },
    }
}
</script>