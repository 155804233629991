<template>
  <div class="questionCategories">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              UPDATE DATA
              <button
                onClick="window.history.back()"
                class="btn btn-sm btn-warning"
                style="float: right;"
              >
                BACK
              </button>
            </div>
            <div class="card-body">
              <form @submit.prevent="putData">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="questionCategory.name"
                    placeholder="Input Category Name"
                  />
                  <div v-if="validation.name">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.name[0] }}
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn-md btn-success"
                    style="float: right;"
                  >
                    UPDATE
                  </button>
                  <button
                    type="reset"
                    class="btn btn-md btn-danger mr-3"
                    style="float: right;"
                  >
                    RESET
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as questionCategoryService from "@/services/questionCategory/questionCategory-services"

export default {
  data() {
    return {
      questionCategory: {},
      validation: [],
    }
  },
  created() {
    questionCategoryService
      .showById(this.$route.params.id)
      .then((response) => {
        this.questionCategory = response.data.data
        console.log(response.data)
      })
      .catch((error) => {
        this.alert(error)
        this.validation = error.response.data
      })
  },
  methods: {
    questionCategoryUpdate() {
      questionCategoryService
        .update(this.questionCategory)
        .then((response) => {
          this.alertSuccess(response.data.message)
          this.$router.push({
            name: "questionCategory",
          })
          console.log(response.data)
        })
        .catch((error) => {
          this.alert(error)
          this.validation = error.response.data
        })
    },
    putData() {
      this.questionCategoryUpdate()
    },
    alertSuccess(message) {
      this.$swal("Success!", message, "success")
    },
    alert(message) {
      this.$swal({
        title: "",
        text: message,
        icon: "error",
        showConfirmButton: true,
      })
    },
  },
}
</script>
