<template>
  <div class="definitions">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              UPDATE DATA
              <button
                onClick="window.history.back()"
                class="btn btn-sm btn-warning"
                style="float: right;"
              >
                BACK
              </button>
            </div>
            <div class="card-body">
              <form @submit.prevent="putData">
                <div class="form-group">
                  <label>Code</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="definition.def_code"
                    placeholder="Input Kode Definisi"
                    disabled
                  />
                  <div v-if="validation.def_code">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.def_code[0] }}
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="definition.def_name"
                    placeholder="Input Name Definisi"
                    disabled
                  />
                  <div v-if="validation.def_name">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.def_name[0] }}
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Point</label>
                  <textarea
                    class="form-control"
                    v-model="definition.def_point"
                    placeholder="Input Poin-Point Definisi"
                  >
                  </textarea>
                  <div v-if="validation.def_point">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.def_point[0] }}
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Description</label>
                  <textarea
                    class="form-control"
                    v-model="definition.def_description"
                    placeholder="Input Deskripsi Definisi"
                  >
                  </textarea>
                  <div v-if="validation.def_description">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.def_description[0] }}
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Job Match</label>
                  <textarea
                    class="form-control"
                    v-model="definition.def_job"
                    placeholder="Input Job Definisi"
                  >
                  </textarea>
                  <div v-if="validation.def_job">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.def_job[0] }}
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn-md btn-success"
                    style="float: right;"
                  >
                    UPDATE
                  </button>
                  <button
                    type="reset"
                    class="btn btn-md btn-danger mr-3"
                    style="float: right;"
                  >
                    RESET
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as definitionService from "@/services/definition/definition-services"

export default {
  data() {
    return {
      definition: {},
      validation: [],
    }
  },
  created() {
    definitionService
      .showById(this.$route.params.id)
      .then((response) => {
        this.definition = response.data.data
        console.log(response.data)
      })
      .catch((error) => {
        this.alert(error)
        this.validation = error.response.data
      })
  },
  methods: {
    definitionUpdate() {
      definitionService
        .update(this.definition)
        .then((response) => {
          this.alertSuccess(response.data.message)
          this.$router.push({
            name: "definition",
          })
          console.log(response.data)
        })
        .catch((error) => {
          this.alert(error)
          this.validation = error.response.data
        })
    },
    putData() {
      this.definitionUpdate()
    },
    alertSuccess(message) {
      this.$swal("Success!", message, "success")
    },
    alert(message) {
      this.$swal({
        title: "",
        text: message,
        icon: "error",
        showConfirmButton: true,
      })
    },
  },
}
</script>
