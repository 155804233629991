import Vue from 'vue'
import Router from 'vue-router'

//use Vue Router in Vue JS
Vue.use(Router);

//import pages
import AuthIndex from '@/views/auth/Index'
import DahsboardIndex from '@/views/dashboard/Index'
import DahsboardData from '@/views/dashboard/Dashboard'
import QuestionCategoryIndex from '@/views/questionCategory/Index'
import QuestionCategoryCreate from '@/views/questionCategory/Create'
import QuestionCategoryUpdate from '@/views/questionCategory/Update'
import QuestionIndex from '@/views/question/Index'
import QuestionCreate from '@/views/question/Create'
import QuestionUpdate from '@/views/question/Update'
import ApplicantDataIndex from '@/views/applicantData/Index'
import ApplicantDataDetail from '@/views/applicantData/Detail'
import DefinitionIndex from '@/views/definition/Index'
import DefinitionUpdate from '@/views/definition/Update'

const routes = [
      {
        path: '/',
        name: 'login',
        component: AuthIndex
      },
      {
        path: '/home',
        name: 'home',
        component: DahsboardIndex,
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: DahsboardData, 
            },
            {
                path: '/questionCategory',
                name: 'questionCategory',
                component: QuestionCategoryIndex,         
            },
            {
                path: '/questionCategory-create',
                name: 'questionCategory-create',
                component: QuestionCategoryCreate,         
            },
            {
                path: '/questionCategory-update/:id',
                name: 'questionCategory-update',
                component: QuestionCategoryUpdate,
            },
            {
                path: '/question',
                name: 'question',
                component: QuestionIndex,         
            },
            {
                path: '/question-create',
                name: 'question-create',
                component: QuestionCreate,         
            },
            {
                path: '/question-update/:id',
                name: 'question-update',
                component: QuestionUpdate,
            },
            {
                path: '/applicantData',
                name: 'applicantData',
                component: ApplicantDataIndex,         
            },
            {
                path: '/applicantData-detail/:id',
                name: 'applicantData-detail',
                component: ApplicantDataDetail,
            },
            {
                path: '/definition',
                name: 'definition',
                component: DefinitionIndex,         
            },
            {
                path: '/definition-update/:id',
                name: 'definition-update',
                component: DefinitionUpdate,
            },
          ],
      }
]

const router = new Router ({
    routes:routes,
    linkActiveClass: 'active'
})

export default router