<template>
    <!-- Footer -->
    <footer class="sticky-footer bg-white">
        <div class="container my-auto">
            <div class="copyright text-center my-auto">
            <span>Copyright &copy; S-Gala.com. 2020</span>
            </div>
        </div>
    </footer>
    <!-- End of Footer -->
</template>

<script>
export default {

}
</script>