import {http} from '@/network/base-url'

export function showData(){
    return http().get('/definition')
}

export function showById(id){
    return http().get('/definition' + '/' + id)
}

export function update(definition){
    return http().post('/definition' + '/' + definition.id, definition)
}