import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        //local
        // apiURL: 'http://localhost:8081/api',
        // serverPath: 'http://localhost:8081',

        //server
        apiURL: 'https://rekrutmen-services.s-gala.com/api',
        serverPath: 'https://rekrutmen-services.s-gala.com'
    },
    mutations: {},
    actions: {},

})