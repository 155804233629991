<template>
  <!-- Sidebar -->
  <ul
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <router-link
      to="/"
      class="sidebar-brand d-flex align-items-center justify-content-center"
    >
      <img :src="require(`@/assets/images/logo.png`)" width="45px" />
      <div class="sidebar-brand-text mx-3">S-Gala Recruitment</div>
    </router-link>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <li class="nav-item">
      <router-link to="/dashboard" class="nav-link">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span> Dashboard</span>
      </router-link>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider" />

    <!-- Heading -->
    <div class="sidebar-heading">
      Menu
    </div>

    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item">
      <a
        class="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseTwo"
        aria-expanded="true"
        aria-controls="collapseTwo"
      >
        <i class="fas fa-fw fa-cog"></i>
        <span> Master Data</span>
      </a>
      <div
        id="collapseTwo"
        class="collapse"
        aria-labelledby="headingTwo"
        data-parent="#accordionSidebar"
      >
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Sub Menu</h6>
          <router-link to="/questionCategory" class="collapse-item"
            >Question Category</router-link
          >
          <router-link to="/question" class="collapse-item"
            >Question</router-link
          >
          <router-link to="/definition" class="collapse-item"
            >Definition</router-link
          >
        </div>
      </div>
    </li>

    <li class="nav-item">
      <router-link to="/applicantData" class="nav-link">
        <i class="fas fa-users"></i>
        <span> Applicant Data</span>
      </router-link>
    </li>
  </ul>
  <!-- End of Sidebar -->
</template>

<script>
export default {
}
</script>
