<template>
  <div class="questionCategories">
    <!-- Page Heading -->
    <h1 class="h4 mb-2 text-gray-800">
      <b> Applicant Data </b>
    </h1>
    <!-- Page Heading -->

    <!-- Element -->
    <b-row>
      <b-col lg="2" class="my-3">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="6"
          label-cols-lg="6"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="4" class="my-3 ml-auto">
        <b-input-group>
          <b-input-group-prepend>
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            debounce="500"
            type="search"
            id="filterInput"
            placeholder="Search..."
          ></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <!-- Element End -->

    <!-- Main table element -->
    <div class="card">
      <div class="card-header">
        Applicant Data
      </div>

      <div class="card-body">
        <b-table
          hover
          class="table table-bordered"
          show-empty
          small
          stacked="md"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filterIncludedFields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #head()="data">
            <span class="font-weight-bold">{{ data.label }}</span>
          </template>

          <template v-slot:cell(number)="data">
            {{ data.item.number ? data.item.number : "-" }}
          </template>

          <template v-slot:cell(name)="data">
            {{ data.item.name ? data.item.name : "-" }}
          </template>

          <template v-slot:cell(age)="data">
            {{ data.item.age + " Tahun" ? data.item.age + " Tahun" : "-" }}
          </template>

          <template v-slot:cell(sex)="data">
            <p v-if="data.item.sex == 1">Pria</p>
            <p v-else-if="data.item.sex == 0">Wanita</p>
            <p v-else>-</p>
          </template>

          <template v-slot:cell(items)="data">
            <table
              class="table table-bordered table-hover"
              style="margin-top: 20px;"
            >
              <thead>
                <tr bgcolor="#F3EEEE">
                  <th><p v-if="data.item.highest_disc == data.item.line3_d"><b>D</b></p>
                  <p v-else>D</p></th>
                  <th><p v-if="data.item.highest_disc == data.item.line3_i"><b>I</b></p>
                  <p v-else>I</p></th>
                  <th><p v-if="data.item.highest_disc == data.item.line3_s"><b>S</b></p>
                  <p v-else>S</p></th>
                  <th><p v-if="data.item.highest_disc == data.item.line3_c"><b>C</b></p>
                  <p v-else>C</p></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><p v-if="data.item.highest_disc == data.item.line3_d"><b>{{data.item.line3_d}}</b></p>
                  <p v-else>{{data.item.line3_d}}</p>
                  </td>
                  <td><p v-if="data.item.highest_disc == data.item.line3_i"><b>{{data.item.line3_i}}</b></p>
                  <p v-else>{{data.item.line3_i}}</p>
                  </td>
                  <td><p v-if="data.item.highest_disc == data.item.line3_s"><b>{{data.item.line3_s}}</b></p>
                  <p v-else>{{data.item.line3_s}}</p>
                  </td>
                  <td><p v-if="data.item.highest_disc == data.item.line3_c"><b>{{data.item.line3_c}}</b></p>
                  <p v-else>{{data.item.line3_c}}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>

          <template v-slot:cell(highest_disc_val)="data">
            {{data.item.highest_disc_val ? data.item.highest_disc_val : '-'}}
          </template>

          <template v-slot:cell(test_date)="data">
            {{
              moment(data.item.test_date).format("D MMMM YYYY")
                ? moment(data.item.test_date).format("D MMMM YYYY")
                : "-"
            }}
          </template>

          <template v-slot:cell(mask_public_self)="data">
            <div
              v-b-tooltip.hover.html.left="
                `POINT: ` +
                  data.item.mask_public_self[0].def_point +
                  ` <br></br>DESCRIPTION: ` +
                  data.item.mask_public_self[0].def_description
              "
            >
              {{
                data.item.mask_public_self[0].def_name
                  ? data.item.mask_public_self[0].def_name
                  : "-"
              }}
            </div>
          </template>

          <template v-slot:cell(core_private_self)="data">
            <div
              v-b-tooltip.hover.html.left="
                `POINT: ` +
                  data.item.core_private_self[0].def_point +
                  ` <br></br>DESCRIPTION: ` +
                  data.item.core_private_self[0].def_description
              "
            >
              {{
                data.item.core_private_self[0].def_name
                  ? data.item.core_private_self[0].def_name
                  : "-"
              }}
            </div>
          </template>

          <template v-slot:cell(mirror_percieved_self)="data">
            <div
              v-b-tooltip.hover.html.left="
                `POINT: ` +
                  data.item.mirror_percieved_self[0].def_point +
                  ` <br></br>DESCRIPTION: ` +
                  data.item.mirror_percieved_self[0].def_description
              "
            >
              {{
                data.item.mirror_percieved_self[0].def_name
                  ? data.item.mirror_percieved_self[0].def_name
                  : "-"
              }}
            </div>
          </template>

          <template v-slot:cell(actions)="row">
            <b-button
              pill
              v-b-tooltip.hover.top="'Detail'"
              size="sm"
              variant="warning"
              @click="
                $router.push({
                  name: 'applicantData-detail',
                  params: { id: row.item.id },
                })
              "
              class="mr-1"
            >
              <i class="fas fa-eye"></i>
            </b-button>
            <b-button
              pill
              v-b-tooltip.hover.top="'Delete'"
              size="sm"
              variant="danger"
              @click.prevent="applicantDataDelete(row.item.id)"
              class="mr-1"
            >
              <i class="fas fa-trash-alt"></i>
            </b-button>
          </template>
        </b-table>
      </div>

      <div class="card-footer">
        <b-col lg="3" class="my-0 ml-auto">
          <b-pagination
            pills
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import * as applicantDataService from "@/services/applicantData/applicantData-services"

const fields = [
  {
    key: "number",
    label: "#",
    sortable: false,
  },
  {
    key: "name",
    label: "Name",
    sortable: true,
    sortDirection: "desc",
  },
  {
    key: "age",
    label: "Age",
    sortable: true,
    sortDirection: "desc",
  },
  {
    key: "sex",
    label: "Gender",
    sortable: true,
    sortDirection: "desc",
  },
  {
    key: "test_date",
    label: "Test Date",
    sortable: true,
    sortDirection: "desc",
  },
  {
    key: "items",
    label: "Result",
    sortable: false,
    sortDirection: "desc",
  },
  {
    key: "highest_disc_val",
    label: "Resume",
    sortable: true,
    sortDirection: "desc",
  },
  {
    key: "mask_public_self",
    label: "MASK",
    sortable: true,
    sortByFormatted: true,
    formatter: (value, key, item) => {
      return item.mask_public_self[0].def_name
    },
    sortDirection: "desc",
  },
  {
    key: "core_private_self",
    label: "CORE",
    sortable: true,
    sortByFormatted: true,
    formatter: (value, key, item) => {
      return item.core_private_self[0].def_name
    },
    sortDirection: "desc",
  },
  {
    key: "mirror_percieved_self",
    label: "MIRROR",
    sortable: true,
    sortByFormatted: true,
    formatter: (value, key, item) => {
      return item.mirror_percieved_self[0].def_name
    },
    sortDirection: "desc",
  },
  {
    key: "actions",
    label: "Actions",
    sortable: false,
  },
]

export default {
  name: "applicantData",

  data() {
    return {
      formData: {
        nama: "",
      },
      fields,
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  watch: {
    items(val) {
      // Set the initial number of items
      this.totalRows = val.length
      console.log("ini length: " + val.length)
    },
  },
  mounted() {
    // ShowData
    this.showData()
  },
  methods: {
    showData: async function() {
      try {
        const response = await applicantDataService.showData()
        // console.log(response)
        this.items.unshift(response.data)
        this.items = response.data.data
        // console.log(this.items)
      } catch (error) {
        this.flashMessage.error({
          title: "Error",
          message: "Something went wrong, please refresh or call IT Team",
          time: 2000,
        })
      }
    },
    applicantDataDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are you sure to delete this data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, i'm not",
        confirmButtonText: "Yes",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          applicantDataService
            .deleteById(id)
            .then((response) => {
              this.alertSuccess(response.data.message)
              this.showData()
              console.log(response)
            })
            .catch((error) => {
              this.alert(error)
              console.log(error.response)
            })
        }
      })
    },
    create() {
      console.log("form submitted.")
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    alertSuccess(message) {
      this.$swal("Success!", message, "success")
    },
    alert(message) {
      this.$swal({
        title: "",
        text: message,
        icon: "error",
        showConfirmButton: true,
      })
    },
  },
}
</script>
