import {http, httpCookie} from '@/network/base-url'

export function logout(){
    return http().post('/logout')
}

export function loginCookie(){
    return httpCookie().get('/sanctum/csrf-cookie')
}

export function login(user){
    return http().post('/login', user)
}