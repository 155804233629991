<template>
  <div class="questionCategories">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              DETAIL DATA
              <button
                onClick="window.history.back()"
                class="btn btn-sm btn-warning"
                style="float: right;"
              >
                BACK
              </button>
            </div>
            <div class="card-body">
              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Name</label>
                      <div>
                        {{ applicantData.name ? applicantData.name : "-" }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Age</label>
                      <div>
                        {{
                          applicantData.age + " Tahun"
                            ? applicantData.age + " Tahun"
                            : "-"
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Gender</label>
                      <div v-if="applicantData.sex == 1">Pria</div>
                      <div v-else-if="applicantData.sex == 0">Wanita</div>
                      <div v-else>-</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Test Date</label>
                      <div>
                        {{
                          moment(applicantData.test_date).format("D MMMM YYYY")
                            ? moment(applicantData.test_date).format(
                                "D MMMM YYYY"
                              )
                            : "-"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div
                      id="printpage"
                    >
                      <div class="page-header">
                        <div class="clearfix"></div>
                        <div style="float: right; margin-bottom: 15px; margin-top: 15px;">
                          <!-- <button class="btn btn-success" id="btn-print">
                            Cetak <i class="fa fa-print"></i>
                          </button> -->
                        </div>
                      </div>
                      <hr />
                      <table
                        class="table table-bordered table-hover"
                        style="margin-top: 20px;"
                      >
                        <thead>
                          <tr bgcolor="#F3EEEE">
                            <th>LINE</th>
                            <th>D</th>
                            <th>I</th>
                            <th>S</th>
                            <th>C</th>
                            <th>*</th>
                            <th>TOTAL</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">MOST</th>
                            <td>{{ applicantData.line1_d ? applicantData.line1_d : "-" }}</td>
                            <td>{{ applicantData.line1_i ? applicantData.line1_i : "-" }}</td>
                            <td>{{ applicantData.line1_s ? applicantData.line1_s : "-" }}</td>
                            <td>{{ applicantData.line1_c ? applicantData.line1_c : "-" }}</td>
                            <td>{{ applicantData.line1_star ? applicantData.line1_star : "-" }}</td>
                            <td>{{ applicantData.line1_total ? applicantData.line1_total : "-"  }}</td>
                          </tr>
                          <tr>
                            <th scope="row">LEAST</th>
                            <td>{{ applicantData.line2_d ? applicantData.line2_d : "-" }}</td>
                            <td>{{ applicantData.line2_i ? applicantData.line2_i : "-" }}</td>
                            <td>{{ applicantData.line2_s ? applicantData.line2_s : "-" }}</td>
                            <td>{{ applicantData.line2_c ? applicantData.line2_c : "-" }}</td>
                            <td>{{ applicantData.line2_star ? applicantData.line2_star : "-" }}</td>
                            <td>{{ applicantData.line2_total ? applicantData.line2_total : "-"  }}</td>
                          </tr>
                          <tr>
                            <th scope="row">CHANGE</th>
                            <td>{{ applicantData.line3_d }}</td>
                            <td>{{ applicantData.line3_i }}</td>
                            <td>{{ applicantData.line3_s }}</td>
                            <td>{{ applicantData.line3_c }}</td>
                            <td bgcolor="#E8DFDF"></td>
                            <td bgcolor="#E8DFDF"></td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="row" style="margin-top: 50px;">
                        <div class="col-sm-4" v-for='mask_public_self in applicantData.mask_public_self' :key="'mps1 '+mask_public_self.id">
                          <!-- <canvas id="chart1"></canvas> -->
                          <hr />
                          <h6 style="color: #9A9797;">MASK/PUBLIC SELF</h6>
                          <p style="font-weight: bold; font-size: 20px;">
                            {{ mask_public_self.def_name ? mask_public_self.def_name : '-' }}
                          </p>
                          <p>
                            {{ mask_public_self.def_point ? mask_public_self.def_point : '-' }}
                          </p>
                        </div>
                        <div class="col-sm-4" v-for='core_private_self in applicantData.core_private_self' :key="'cps '+core_private_self.id">
                          <!-- <canvas id="chart2"></canvas> -->
                          <hr />
                          <h6 style="color: #9A9797;">CORE/PRIVATE SELF</h6>
                          <p style="font-weight: bold; font-size: 20px;">
                            {{ core_private_self.def_name ? core_private_self.def_name : '-' }}
                          </p>
                          <p>
                            {{ core_private_self.def_point ? core_private_self.def_point : '-' }}
                          </p>
                        </div>
                        <div class="col-sm-4" v-for='mirror_percieved_self in applicantData.mirror_percieved_self' :key="'mps2 '+mirror_percieved_self.id">
                          <!-- <canvas id="chart3"></canvas> -->
                          <hr />
                          <h6 style="color: #9A9797;">MIRROR/PERCEIVED SELF</h6>
                          <p style="font-weight: bold; font-size: 20px;">
                            {{ mirror_percieved_self.def_name ? mirror_percieved_self.def_name : '-' }}
                          </p>
                          <p>
                            {{ mirror_percieved_self.def_point ? mirror_percieved_self.def_point : '-' }}
                          </p>
                        </div>
                      </div>
                      <div class="row" v-for='mirror_percieved_self in applicantData.mirror_percieved_self' :key=mirror_percieved_self.id style="margin-top: 40px">
                        <div class="col-sm-8">
                          <p style="font-weight: bold; font-size: 20px;">
                            DESKRIPSI KEPRIPADIAN
                          </p>
                          <p style="color: #A8A7A7;" align="justify">
                            {{ mirror_percieved_self.def_description ? mirror_percieved_self.def_description : '-' }}
                          </p>
                        </div>
                        <div class="col-sm-4">
                          <p style="font-weight: bold; font-size: 20px;">
                            JOB MATCH
                          </p>
                          <p style="color: #A8A7A7;" align="justify">
                            {{ mirror_percieved_self.def_job ? mirror_percieved_self.def_job : '-' }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br><br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as applicantDataService from "@/services/applicantData/applicantData-services"

export default {
  data() {
    return {
      applicantData: {},
      validation: [],
    }
  },
  created() {
    applicantDataService.showById(this.$route.params.id)
      .then((response) => {
        this.applicantData = response.data.data
      })
  },
  methods: {
    alertSuccess(message) {
      this.$swal("Success!", message, "success")
    },
    alert(message) {
      this.$swal({
        title: "",
        text: message,
        icon: "error",
        showConfirmButton: true,
      })
    },
  },
}
</script>
