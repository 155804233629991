<template>
  <div class="container">
    <!-- Page Heading -->
    <h1 class="h4 mb-2 text-gray-800">
      <b>Dashboard</b>
    </h1>
    <!-- Page Heading -->

    <!-- Page Heading -->
    <div
      class="d-sm-flex align-items-center justify-content-between mb-4"
    ></div>

    <!-- Content Row -->
    <div class="row">
      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                >
                  Applicant Today
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ items_applicant_today ? items_applicant_today : 0 }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-user fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  Total Applicant
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ items_total_applicants ? items_total_applicants : 0 }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-users fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-info shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-info text-uppercase mb-1"
                >
                  Total Questions
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto">
                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                      {{ items_total_questions ? items_total_questions : 0 }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-question-circle fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Pending Requests Card Example -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-warning text-uppercase mb-1"
                >
                  Total Definitions
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ items_total_definitions ? items_total_definitions : 0 }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-book fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Content Row -->
    <div class="row">
      <div class="col-lg-6 mb-4">
        <!-- Approach -->
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-success">
              Application Description
            </h6>
          </div>
          <div class="card-body">
            <div class="text-center">
              <img
                class="img-fluid px-3 px-sm-4 mt-3 mb-4"
                style="width: 20rem;"
                src="@/assets/images/detailed_analysis.svg"
                alt=""
              />
            </div>
            <p>
              S-Gala Recruitment is Content Management System to Manage Applicant that apply for job in S-Gala.com. One of the method that used in the recruitment process DISC Personality Test.
            </p>
            <p class="mb-0">
              This application consists of several menu such as Dashboard, Master Data (Question Category, Question, Definition) and Applicant Data.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-4">
        <!-- Illustrations -->
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-success">Menu Explanation</h6>
          </div>
          <div class="card-body">
            <div class="text-center">
              <img
                class="img-fluid px-3 px-sm-4 mt-3 mb-4"
                style="width: 20rem;"
                src="@/assets/images/performance_overview.svg"
                alt="img-performance"
              />
            </div>
            <p>
              You can click the button below to know further about each menu in
              this application.
            </p>
            <b-button v-b-modal.modal-lg variant="success"
              >Learn more →</b-button
            >
            <b-modal
              id="modal-lg"
              size="lg"
              title="Menu Explanation"
              ok-only
              ok-title="Close"
              ok-variant="danger"
            >
              <p class="my-2">
                <b>Dashboard</b>
              </p>
              <p>
                Dashboard use to know about application big picture and read
                simple explanation about this application.
              </p>
              <p class="my-2">
                <b>Master Data/Question Category</b>
              </p>
              <p>
                This menu is the category master of DISC Personality Test.
              </p>
              <p class="my-2">
                <b>Master Data/Question</b>
              </p>
              <p>
                This menu is the question master of DISC Personality Test.
              </p>
              <p class="my-2">
                <b>Master Data/Definition</b>
              </p>
              <p>
                This menu is the definition master of DISC Personality Test.
              </p>
              <p class="my-2">
                <b>Applicant Data</b>
              </p>
              <p>
                This menu is the result of all applicants.
              </p>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as dashboardService from "@/services/dashboard/dashboard-services"

export default {
  data() {
    return {
      dataPoints: null,
      height: 20,
      items_applicant_today: [],
      items_total_applicants: [],
      items_total_questions: [],
      items_total_definitions: [],
    }
  },
  mounted() {
    this.showDataApplicantToday()
    this.showDataTotalApplicants()
    this.showDataTotalQuestions()
    this.showDataTotalDefinitions()
  },
  methods: {
    showDataApplicantToday: async function() {
      try {
        const response = await dashboardService.showDataApplicantToday()
        console.log(response)
        this.items_applicant_today.unshift(response.data)
        this.items_applicant_today = response.data.data
        console.log(this.items_applicant_today)
      } catch (error) {
        this.flashMessage.error({
          title: "Error",
          message:
            "Something went wrong, please refresh or call IT Team",
          time: 2000,
        })
      }
    },

    showDataTotalApplicants: async function() {
      try {
        const response = await dashboardService.showDataTotalApplicants()
        console.log(response)
        this.items_total_applicants.unshift(response.data)
        this.items_total_applicants = response.data.data
        console.log(this.items_total_applicants)
      } catch (error) {
        this.flashMessage.error({
          title: "Error",
          message:
            "Something went wrong, please refresh or call IT Team",
          time: 2000,
        })
      }
    },

    showDataTotalQuestions: async function() {
      try {
        const response = await dashboardService.showDataTotalQuestions()
        console.log(response)
        this.items_total_questions.unshift(response.data)
        this.items_total_questions = response.data.data
        console.log(this.items_total_questions)
      } catch (error) {
        this.flashMessage.error({
          title: "Error",
          message:
            "Something went wrong, please refresh or call IT Team",
          time: 2000,
        })
      }
    },

    showDataTotalDefinitions: async function() {
      try {
        const response = await dashboardService.showDataTotalDefinitions()
        console.log(response)
        this.items_total_definitions.unshift(response.data)
        this.items_total_definitions = response.data.data
        console.log(this.items_total_definitions)
      } catch (error) {
        this.flashMessage.error({
          title: "Error",
          message:
            "Something went wrong, please refresh or call IT Team",
          time: 2000,
        })
      }
    },

  },
  computed: {
    myStyles() {
      return {
        height: `${this.height}px`,
        position: "relative",
      }
    },
  },
}
</script>

<style>
.container {
  max-width: 800px;
  margin: 0 auto;
}
h1 {
  font-family: "Helvetica", Arial;
  color: #464646;
  text-transform: uppercase;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 15px;
  font-size: 28px;
  margin-top: 0;
}
.Chart {
  padding: 20px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  margin: 50px 0;
}
</style>
