<template>
  <div class="questionCategories">
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              ADD DATA
              <button
                onClick="window.history.back()"
                class="btn btn-sm btn-warning"
                style="float: right;"
              >
                BACK
              </button>
            </div>
            <div class="card-body">
              <form @submit.prevent="postData">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="questionCategory.name"
                    placeholder="Input Category Name"
                  />
                  <div v-if="validation.name">
                    <div class="alert alert-danger mt-1" role="alert">
                      {{ validation.name[0] }}
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn btn-md btn-success"
                  style="float: right"
                >
                  SAVE
                </button>
                <button
                  type="reset"
                  class="btn btn-md btn-danger mr-3"
                  style="float: right"
                >
                  RESET
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as questionCategoryService from "@/services/questionCategory/questionCategory-services"

export default {
  data() {
    return {
      questionCategory: {},
      validation: [],
    }
  },
  methods: {
    questionCategoryStore(questionCategory) {
      questionCategoryService.create(questionCategory)
        .then((response) => {
          this.alertSuccess(response.data.message)
          this.$router.push({
            name: "questionCategory",
          })
          console.log(response.data)
        })
        .catch((error) => {
          this.alert(error)
          this.validation = error.response.data
        })
    },
    postData() {
        this.questionCategoryStore(this.questionCategory)
    },
    alertSuccess(message) {
      this.$swal("Success!", message, "success")
    },
    alert(message) {
      this.$swal({
        title: "",
        text: message,
        icon: "error",
        showConfirmButton: true,
      })
    },
  },
}
</script>
