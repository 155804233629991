import store from '../store'
import axios from 'axios'

export function http(){
    return axios.create({
        baseURL: store.state.apiURL
    })
}

export function httpCookie(){
    return axios.create({
        baseURL: store.state.serverPath
    })
}

export function httpFile(){
    return axios.create({
        baseURL: store.state.apiURL,
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
}