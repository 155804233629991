<template>
  <div class="questions">
    <!-- Page Heading -->
    <h1 class="h4 mb-2 text-gray-800">
      Master Data /
      <b>Question</b>
    </h1>
    <!-- Page Heading -->

    <!-- Element -->
    <b-row>
      <b-col lg="2" class="my-3">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="6"
          label-cols-lg="6"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="4" class="my-3 ml-auto">
        <b-input-group>
          <b-input-group-prepend>
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Search..."
          ></b-form-input>
          <!-- <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''" variant="danger">Delete</b-button>
          </b-input-group-append> -->
        </b-input-group>
      </b-col>
    </b-row>
    <!-- Element End -->

    <!-- Main table element -->
    <div class="card">
      <div class="card-header">
        Question
        <!-- <b-button
          pill
          size="sm"
          variant="success"
          @click="$router.push('question-create')"
          class="float-right"
        >
          <i class="fas fa-plus"></i> Add Data
        </b-button> -->
      </div>

      <div class="card-body">
        <b-table
          hover
          class="table table-bordered"
          show-empty
          small
          stacked="md"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filterIncludedFields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >

          <template #head()="data">
            <span class="font-weight-bold">{{ data.label }}</span>
          </template>

          <template v-slot:cell(number)="data">
              {{ data.item.number ? data.item.number : '-' }}
          </template>

          <template v-slot:cell(category_name)="data">
              {{ data.item.category_name ? data.item.category_name : '-' }}
          </template>

          <template v-slot:cell(question)="data">
              {{ data.item.question ? data.item.question : '-' }}
          </template>

          <template v-slot:cell(actions)="row">
            <b-button
              pill
              v-b-tooltip.hover.top="'Update'"
              size="sm"
              variant="primary"
              @click="$router.push({name: 'question-update', params: { id: row.item.id }})"
              class="mr-1"
            >
              <i class="fas fa-pencil-alt"></i>
            </b-button>
            <!-- <b-button
              pill
              v-b-tooltip.hover.top="'Delete'"
              size="sm"
              variant="danger"
              @click.prevent="questionDelete(row.item.id)"
              class="mr-1"
            >
              <i class="fas fa-trash-alt"></i>
            </b-button> -->
          </template>
        </b-table>

      </div>

      <div class="card-footer">
        <b-col lg="3" class="my-0 ml-auto">
          <b-pagination
            pills
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </div>
    </div>
  </div>
</template>

<style>
label {
    font-weight: bold;
}
</style>

<script>
import * as questionService from "@/services/question/question-services"
import axios from 'axios'

const fields = [
  {
    key: "number",
    label: "#",
    sortable: false,
  },
  {
    key: "category_name",
    label: "Category Name",
    sortable: true,
    sortDirection: "desc",
  },
  {
    key: "question",
    label: "Name",
    sortable: true,
    sortDirection: "desc",
  },
  {
    key: "actions",
    label: "Actions",
    sortable: false,
  },
]

export default {
  name: "question",

  data() {
    return {
      formData: {
        nama: '',
      },
      fields,
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  watch: {
    items(val) {
        // Set the initial number of items
        this.totalRows = val.length
        console.log('ini length: '+val.length)   
    },
  },
  mounted() {
    // ShowData
    this.showData()
  },
  methods: {
    showData: async function() {
      try {
        const response = await questionService.showData()
        // console.log(response)
        this.items.unshift(response.data) 
        this.items = response.data.data
        // console.log(this.items)
      } catch (error) {
        this.flashMessage.error({
          title: 'Error',
          message: "Something went wrong, please refresh or call IT Team",
          time: 2000,
        })
      }
    },
    questionDelete(id){
        this.$swal({
            title: 'Warning!',
            text: "Are you sure to delete this data?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: "No, i'm not",
            confirmButtonText: "Yes",
            reverseButtons: true,
            }).then((result) => {
            if (result.value) {
                axios.delete(`http://localhost:8081/api/question/${id}`)
                .then(response =>{
                    this.alertSuccess(response.data.message)
                    this.showData()
                    console.log(response)
                }).catch(error => {
                    this.alert(error)
                    console.log(error.response)
                })
            }
        })
    },
    create() {
      console.log("form submitted.")
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    alertSuccess(message){
        this.$swal('Success!',
            message,
            'success'
        )
    },
    alert(message){
        this.$swal({
            title: "",
            text: message,
            icon: "error",
            showConfirmButton: true
        })
    },
  },
}
</script>