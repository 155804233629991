import {http} from '@/network/base-url'

export function showDataApplicantToday(){
    return http().get('/dashboard/applicant_today')
}

export function showDataTotalApplicants(){
    return http().get('/dashboard/total_applicants')
}

export function showDataTotalQuestions(){
    return http().get('/dashboard/total_questions')
}

export function showDataTotalDefinitions(){
    return http().get('/dashboard/total_definitions')
}