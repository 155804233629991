import {http} from '@/network/base-url'

export function showData(){
    return http().get('/question_category')
}

export function showById(id){
    return http().get('/question_category' + '/' + id)
}

export function create(questionCategory){
    return http().post('/question_category', questionCategory)
}

export function update(questionCategory){
    return http().post('/question_category' + '/' + questionCategory.id, questionCategory)
}

export function deleteById(id){
    return http().delete('/question_category' + '/' + id)
}